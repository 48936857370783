@import 'styles/animations';
@import 'styles/breakpoints';

.root {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--root-padding, 2.4rem);

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }
}

.eyebrow {
    width: 100%;
    padding: var(--eyebrow-padding, 0 0 4.8rem);
}

.productList {
    display: grid;
    overflow: hidden;
    width: 100%;
    max-width: var(--block-max-width);
    padding: 0;
    margin: 0 auto;
    grid-auto-columns: 1fr;
    grid-gap: var(--gridGap, 1rem);
    grid-template-areas: var(--gridColumnAreas, 'a');
    list-style: none;
}

.listItem {
    box-sizing: border-box;
}

@include smallOnly {
    .root {
        &:has(.productItem) {
            --gridColumnAreas: 'a a';
        }
    }
}

@include medium {
    .root {
        --root-padding: 4.8rem;
        --gridColumnAreas: 'a a';
        --gridGap: 1.2rem;
    }
}

@include large {
    .root {
        --root-padding: var(--block-padding-side);
        --eyebrow-padding: 0 0 9.6rem;
        --gridColumnAreas: 'a a a';
    }
}
