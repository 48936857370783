@import 'styles/animations';
@import 'styles/breakpoints';

.card {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: var(--card-background-color);
}

.cardLinkWrap {
    width: 100%;
}

.featured {
    position: absolute;
    top: 2rem;
    right: 2.4rem;
    left: auto;
    width: 8.416rem;
    height: 8.416rem;
    color: var(--colors-blue);
}

.cardInner {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    background-color: var(--colors-white);

    .theme--makers-reserve & {
        background-color: var(--colors-cream);
    }
}

.cardContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--cardContent-padding, 3.6rem);
}

.imageContainer {
    position: relative;
    display: flex;
    width: 100%;
    height: 0;
    justify-content: center;
    padding-top: 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    picture {
        width: 100%;
        height: auto;
    }
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.isVisible picture img {
        opacity: 1;
    }
}

.eyebrow {
    margin-bottom: 0.6rem;
    text-transform: uppercase;
}

.textGroup {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 3.6rem;
}

.cta,
.eyebrow {
    color: var(--colors-blue);
    text-align: center;

    .theme--makers-reserve & {
        color: var(--colors-makersReserveBlack);
    }
}

@include medium {
    .card {
        --cardContent-padding: 2.4rem 2.4rem 5rem;
    }

    .eyebrow {
        margin-bottom: 1rem;
    }

    .award {
        max-width: 27rem;
    }
}

@include xxxLarge {
    .card {
        --cardContent-padding: 5.6rem 4.6rem;
    }
}
